<template>
  <div class='py-2 lg:py-8 px-4 lg:px-0'>
    <div class='border-2 border-gray-100 px-4 lg:px-8 py-8 rounded-lg shadow-md text-center font-light'>
      <p class='text-lg lg:text-xl'>사전등록 마감일은 <span style='color: #513A76;' class='text-xl lg:text-2xl font-bold'>3월 7일</span> 입니다.</p>
      <p class='text-xs lg:text-sm text-gray-600'>* 코로나19 방역지침 준수를 위해 등록은 사전등록만 가능합니다. (현장 등록 불가)</p>
      <div class='mx-auto text-base lg:text-lg mt-4 underline'>
        <a href='https://ksso2022.kossoasc.org/registration/reg.html' target='_blank'>사전등록 바로가기</a>
      </div>
    </div>
    <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
      <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/3'>사전등록(온라인/오프라인)</h2>
      <div class='mt-2 w-full lg:w-2/3 pl-6 lg:pl-0 flex flex-col lg:flex-row gap-y-4 lg:gap-x-4'>
        <div 
          class='w-full lg:w-48 py-4 lg:py-6 h-28 lg:h-80 flex flex-row lg:flex-col justify-between items-start'
          style='background-color: #F9F7FD; color: #513A76;'>
          <div class='px-6'>
            <h2 class='text-lg lg:text-xl'>회원</h2>
            <h2 class='text-xs lg:text-base font-light'>교수, 개원의, 봉직의, 교직의</h2>
          </div>
          <div class='p-4 text-xl lg:text-2xl'>
            50,000원
          </div>
        </div>
        <div 
          class='w-full lg:w-48 py-4 lg:py-6 h-28 lg:h-80 flex flex-row lg:flex-col justify-between items-start'
          style='background-color: #F9F7FD; color: #513A76;'>
          <div class='px-6'>
            <h2 class='text-lg lg:text-xl'>회원</h2>
            <h2 class='text-xs lg:text-base font-light'>전임의, 수련의, 전공의, 영양사, 운동사, 간호사, 연구원 및 기타 참가자</h2>
          </div>
          <div class='p-4 text-xl lg:text-2xl'>
            30,000원
          </div>
        </div>
        <div 
          class='w-full lg:w-48 py-4 lg:py-6 h-28 lg:h-80 flex flex-row lg:flex-col justify-between items-start'
          style='background-color: #EBF6F4; color: #08715B;'>
          <div class='px-6'>
            <h2 class='text-lg lg:text-xl'>비회원</h2>
            <h2 class='text-xs lg:text-base font-light'>교수, 개원의, 봉직의, 교직의</h2>
          </div>
          <div class='p-4 text-xl lg:text-2xl'>
            70,000원
          </div>
        </div>
        <div 
          class='w-full lg:w-48 py-4 lg:py-6 h-28 lg:h-80 flex flex-row lg:flex-col justify-between items-start'
          style='background-color: #EBF6F4; color: #08715B;'>
          <div class='px-6'>
            <h2 class='text-lg lg:text-xl'>비회원</h2>
            <h2 class='text-xs lg:text-base font-light'>전임의, 수련의, 전공의, 영양사, 운동사, 간호사, 연구원 및 기타 참가자</h2>
          </div>
          <div class='p-4 text-xl lg:text-2xl'>
            50,000원
          </div>
        </div>
      </div>
    </div>
    <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
      <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/3'>평점 안내</h2>

      <div class='w-full lg:w-2/3 pl-6 lg:pl-0'>
        <h3 class='mt-2 text-lg lg:text-xl text-gray-900 relative'>
          1. 대한의사협회 6평점, 부분평점인정 (입퇴실 시간에 따른 부분 평점 지급)
        </h3>
        <p class='whitespace-pre-line mt-2 text-gray-600 text-sm lg:text-base leading-6'>
          - 체류시간 1시간 이상 ~ 2시간 미만 ⇨ 1평점
        </p>
        <p class='whitespace-pre-line mt-2 text-gray-600 text-sm lg:text-base leading-6'>
          - 체류시간 2시간 이상 ~ 3시간 미만 ⇨ 2평점
        </p>
        <p class='whitespace-pre-line mt-2 mb-8 text-gray-600 text-sm lg:text-base leading-6'>
          - 체류시간 3시간 이상 ~ 4시간 미만 ⇨ 3평점
        </p>
        <h3 class='mt-2 mb-8 text-lg lg:text-xl text-gray-900 relative'>
          2. 한국영양교육평가원 임상영양사 전문연수교육(CPD) 4평점
        </h3>
      </div>


      <div class='w-full lg:w-2/3 pl-6 lg:pl-0 hidden'>
        <ul style='list-style: inside;'
            class='whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
          <li class='mt-2 mb-8'>
            대한의사협회 6평점, 부분평점인정 (입퇴실 시간에 따른 부분 평점 지급)
            <div class='px-2'>체류시간 1시간 이상 ~ 2시간 미만 ⇨ 1평점</div>
            <div class='px-2'>체류시간 2시간 이상 ~ 3시간 미만 ⇨ 2평점</div>
            <div class='px-2'>체류시간 3시간 이상 ~ 4시간 미만 ⇨ 3평점</div>
          </li>
          <li class='mt-2 mb-8'>
            한국영양교육평가원 임상영양사 전문연수교육(CPD) 4평점
          </li>
          <li class='mt-2 mb-8'>
            대한운동사협회 (안내 예정)
          </li>
        </ul>
      </div>
    </div>
    <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
      <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/3'>사전 등록 방법</h2>
      <div class='mt-2 w-full lg:w-2/3 pl-6 lg:pl-0 flex flex-col gap-y-4 lg:gap-y-8'>
        <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/images/member.png' class='w-full lg:w-3/4'>
        <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/images/non-member.png' class='w-full lg:w-3/4'>
      </div>
    </div>
    <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
      <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/3'>결제방법</h2>
      <div class='w-full lg:w-2/3 pl-6 lg:pl-0'>
        <h3 class='mt-2 text-lg lg:text-xl text-gray-900 relative'>
          1. 온라인 카드결제
        </h3>
        <p class='whitespace-pre-line mt-2 mb-8 text-gray-600 text-sm lg:text-base leading-6'>
          - 사용가능 카드: BC카드, 국민카드, 하나카드, 외환카드, 삼성카드, 롯데카드, 현대카드, 신한카드, 농협카드
        </p>
        <h3 class='mt-2 text-lg lg:text-xl text-gray-900 relative'>
          2. 무통장입금
        </h3>
        <p class='whitespace-pre-line mt-2 mb-8 text-gray-600 text-sm lg:text-base leading-6'>
          - 계좌번호: 하나은행 / 584-910003-16504(예금주: 대한비만학회 등록비)
        </p>
      </div>
    </div>
    <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
      <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/3'>등록 취소 및 환불</h2>
      <div class='w-full lg:w-2/3 pl-6 lg:pl-0'>
        <ul style='list-style: inside;'
            class='whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
          <li class='mt-2 mb-8'>
            등록 취소는 사전등록 기간 내에만 가능하며, 사전등록 마감일 이후에는 환불이 불가능합니다.(사전등록 기간 내 환불 시 100% 환불)
          </li>
          <li class='mt-2 mb-8'>
          등록 취소 요청은 환불신청서를 작성하시어 사전등록 마감일 이전까지 사무국 이메일로 보내주셔야 합니다.<br>
          사무국 이메일: kossoasc@kossoasc.org<br>
          <a href='https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/downloads/2022%EB%85%84+%EC%A0%9C55%EC%B0%A8+%EB%8C%80%ED%95%9C%EB%B9%84%EB%A7%8C%ED%95%99%ED%9A%8C+%EC%B6%98%EA%B3%84%ED%95%99%EC%88%A0%EB%8C%80%ED%9A%8C+%EB%93%B1%EB%A1%9D+%EC%B7%A8%EC%86%8C+%EB%B0%8F+%EB%93%B1%EB%A1%9D%EB%B9%84+%ED%99%98%EB%B6%88+%EC%8B%A0%EC%B2%AD%EC%84%9C.docx' class='underline'>환불신청서 다운로드</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'PreregistrationInformation',
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor'
    ]),
  }
}
</script>
